import { RecorderRequest } from "./types";

const Recorder = (request: RecorderRequest): Promise<string> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };

  const apiURL =
    "https://mainstem-ml-supervised-recorder.azurewebsites.net/api/RecordData";

  return new Promise(function (resolve) {
    fetch(apiURL, requestOptions)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          resolve("Success");
        } else {
          throw new Error("Failed to record data");
        }
      })
      .catch((ex) => {
        throw ex;
      });
  });
};

export { Recorder };
