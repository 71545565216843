import "./App.css";
// React
import { useState } from "react";
// MainStem - UI
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  CenterScreen,
  FormattedNumber,
  Grid,
  GridItem,
  InputNumber,
  InputText,
  Label,
  ListGroup,
  Loader,
  Switch,
  TextArea,
  ToastContainer,
  theme,
  toast,
  useOnFirstLoad,
} from "@mainstem/react-mainstem";
// MainStem - API
import { TerminatorResults } from "./api/terminator/types";
import { TerminatorGetParentProducts } from "./api/terminator";
// Font Awesome Icons
import {
  faCrystalBall,
  faSignIn,
  faSignOut,
} from "@fortawesome/pro-light-svg-icons";
import { randomNames } from "./random";
import { Recorder } from "./api/recorder";

function App() {
  // Loading Indicators
  const [loadingTerminator, setLoadingTerminator] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  // View State
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");
  const [classification, setClassification] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [result1Choice, setResult1Choice] = useState<string | undefined>(
    undefined
  );
  const [result2Choice, setResult2Choice] = useState<string | undefined>(
    undefined
  );
  const [result3Choice, setResult3Choice] = useState<string | undefined>(
    undefined
  );

  const [perfectProductMatchID, setPerfectProductMatchID] = useState<
    number | undefined
  >(undefined);

  const [terminatorTime, setTerminatorTime] = useState<number | undefined>(
    undefined
  );
  // API Results
  const [terminatorResults, setTerminatorResults] = useState<
    TerminatorResults[] | undefined
  >(undefined);

  const findParentProduct = (randomItemName?: string) => {
    if (!randomItemName) {
      if (!name) {
        toast.error("Please enter a name");
        return;
      }
    }
    setTerminatorTime(undefined);
    setTerminatorResults([]);
    setLoadingTerminator(true);

    setResult1Choice(undefined);
    setResult2Choice(undefined);
    setResult3Choice(undefined);
    setPerfectProductMatchID(undefined);

    const dateStart = new Date();

    const apiRequest = {
      name: randomItemName || name || "",
      sku,
      category,
      subCategory,
      classification,
      imageUrl,
      description,
    };

    TerminatorGetParentProducts(apiRequest)
      .then((apiResponse) => {
        const dateEnd = new Date();
        var dif = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;
        setTerminatorTime(dif);
        setTerminatorResults(apiResponse);
      })
      .finally(() => {
        setLoadingTerminator(false);
      });
  };

  const handleRandomTest = () => {
    const randomItem =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    setName(randomItem);
    findParentProduct(randomItem);
  };

  const handleRecord = () => {
    if (
      user &&
      terminatorResults &&
      result1Choice &&
      result2Choice &&
      result3Choice
    ) {
      setLoadingSave(true);
      const apiRequest = {
        user,
        name,
        sKU: sku,
        category,
        subCategory,
        classification,
        imageURL: imageUrl,
        description,
        match1ID: terminatorResults[0].id,
        match1Confidence: terminatorResults[0].confidence,
        match1Decision: result1Choice,
        match2ID: terminatorResults[1].id,
        match2Confidence: terminatorResults[1].confidence,
        match2Decision: result2Choice,
        match3ID: terminatorResults[2].id,
        match3Confidence: terminatorResults[2].confidence,
        match3Decision: result3Choice,
        perfectProductMatchID,
      };

      Recorder(apiRequest)
        .then((apiResponse) => {
          if (apiResponse === "Success") {
            toast.success("Recorded Successfully");

            setName("");
            setSku("");
            setCategory("");
            setSubCategory("");
            setClassification("");
            setImageUrl("");
            setDescription("");

            setTerminatorResults(undefined);
            setTerminatorTime(undefined);

            setResult1Choice(undefined);
            setResult2Choice(undefined);
            setResult3Choice(undefined);
            setPerfectProductMatchID(undefined);
          } else {
            toast.error("Error Recording");
          }
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };

  useOnFirstLoad(() => {
    const localUser = localStorage.getItem("user");
    if (localUser) {
      setUser(localUser);
      setLoggedIn(true);
    }
  });

  return (
    <>
      {!loggedIn || !user ? (
        <>
          <CenterScreen>
            <Card style={{ minWidth: 420 }}>
              <CardHeader
                title={<CardHeaderTitle>MainStem Login</CardHeaderTitle>}
              />
              <CardBody>
                <InputText
                  label="Username"
                  onChange={(newValue) => {
                    setUser(newValue);
                  }}
                  onEnter={() => {
                    if (user) {
                      localStorage.setItem("user", user);
                      setLoggedIn(true);
                    }
                  }}
                  value={user}
                />
                <Button
                  block
                  color="fancy"
                  icon={faSignIn}
                  onClick={() => {
                    if (user) {
                      localStorage.setItem("user", user);
                      setLoggedIn(true);
                    }
                  }}
                >
                  Login
                </Button>
              </CardBody>
            </Card>
          </CenterScreen>
        </>
      ) : (
        <>
          <div style={{ display: "flex", padding: 25 }}>
            <div style={{ width: 600 }}>
              <Card>
                <CardHeader
                  title={<CardHeaderTitle>User Information</CardHeaderTitle>}
                />
                <CardBody>
                  <Label>Logged In As</Label>
                  <br />
                  {user}
                  <br />
                  <br />
                  <Button block color="danger" icon={faSignOut}>
                    Logout
                  </Button>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader
                  title={
                    <CardHeaderTitle>
                      MainStem Parent Product Finder
                    </CardHeaderTitle>
                  }
                />
                <CardBody>
                  <p>
                    Enter in some product data below, or click the button to
                    test a random product name.
                  </p>
                  <Button block color="fancy-gray" onClick={handleRandomTest}>
                    Test A Random Product
                  </Button>
                </CardBody>
              </Card>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ paddingLeft: 25 }}>
                <Grid>
                  <GridItem lg={6} md={12} sm={12} xs={12}>
                    <Card>
                      <CardBody topPadding>
                        <InputText
                          label="Product Name"
                          onChange={(newValue) => {
                            setName(newValue);
                          }}
                          labelRequired={true}
                          value={name}
                        />
                        <InputText
                          label="Product SKU"
                          onChange={(newValue) => {
                            setSku(newValue);
                          }}
                          value={sku}
                        />
                        <Grid>
                          <GridItem lg={4}>
                            <InputText
                              label="Category"
                              onChange={(newValue) => {
                                setCategory(newValue);
                              }}
                              value={category}
                            />
                          </GridItem>
                          <GridItem lg={4}>
                            <InputText
                              label="SubCategory"
                              onChange={(newValue) => {
                                setSubCategory(newValue);
                              }}
                              value={subCategory}
                            />
                          </GridItem>
                          <GridItem lg={4}>
                            <InputText
                              label="Classification"
                              onChange={(newValue) => {
                                setClassification(newValue);
                              }}
                              value={classification}
                            />
                          </GridItem>
                        </Grid>
                        <InputText
                          label="Image URL"
                          placeholder="https://www.example.com/image.jpg"
                          onChange={(newValue) => {
                            setImageUrl(newValue);
                          }}
                          value={imageUrl}
                        />
                        <TextArea
                          label="Description"
                          onChange={(newValue) => {
                            setDescription(newValue);
                          }}
                          value={description}
                        />
                        <Button
                          block={true}
                          color="fancy"
                          icon={faCrystalBall}
                          onClick={() => {
                            findParentProduct();
                          }}
                        >
                          Find Parent Product
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg={6} md={12} sm={12} xs={12}>
                    <Card>
                      <CardHeader
                        actions={
                          <CardHeaderActions>
                            {terminatorTime && (
                              <>
                                <FormattedNumber value={terminatorTime} />
                                &nbsp;seconds
                              </>
                            )}
                          </CardHeaderActions>
                        }
                        title={
                          <CardHeaderTitle>
                            MainStem Parent Product Matching Results
                          </CardHeaderTitle>
                        }
                      />
                      <CardBody>
                        {loadingTerminator ? (
                          <Loader />
                        ) : (
                          <>
                            <ListGroup>
                              {terminatorResults?.map((result, index) => {
                                return (
                                  <li
                                    key={result.id}
                                    style={{
                                      border: "1px solid rgb(204, 204, 204)",
                                      marginBottom: 15,
                                      padding: 15,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex", flex: 1 }}>
                                        <div
                                          style={{ display: "flex", flex: 1 }}
                                        >
                                          <div
                                            style={{ display: "flex", flex: 1 }}
                                          >
                                            <a
                                              href={result.url}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              #{result.id} - {result.name}
                                            </a>
                                          </div>
                                          <div
                                            style={{ display: "flex", width: 160 }}
                                          >
                                            <Button color='link' onClick={()=>{setPerfectProductMatchID(result.id)}}>
                                              Copy ID To Perfect Match
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 0,
                                          marginLeft: 25,
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <div style={{ width: 150 }}>
                                            <Switch
                                              isChecked={
                                                index === 0
                                                  ? result1Choice === "Way Off"
                                                  : index === 1
                                                  ? result2Choice === "Way Off"
                                                  : index === 2
                                                  ? result3Choice === "Way Off"
                                                  : false
                                              }
                                              offText="Way Off"
                                              onChange={() => {
                                                if (index === 0) {
                                                  setResult1Choice("Way Off");
                                                } else if (index === 1) {
                                                  setResult2Choice("Way Off");
                                                } else if (index === 2) {
                                                  setResult3Choice("Way Off");
                                                }
                                              }}
                                              onText="Way Off"
                                            />
                                          </div>
                                          <div style={{ width: 150 }}>
                                            <Switch
                                              isChecked={
                                                index === 0
                                                  ? result1Choice === "Close"
                                                  : index === 1
                                                  ? result2Choice === "Close"
                                                  : index === 2
                                                  ? result3Choice === "Close"
                                                  : false
                                              }
                                              offText="Close"
                                              onChange={() => {
                                                if (index === 0) {
                                                  setResult1Choice("Close");
                                                } else if (index === 1) {
                                                  setResult2Choice("Close");
                                                } else if (index === 2) {
                                                  setResult3Choice("Close");
                                                }
                                              }}
                                              onText="Close"
                                            />
                                          </div>
                                          <div style={{ width: 150 }}>
                                            <Switch
                                              isChecked={
                                                index === 0
                                                  ? result1Choice === "Perfect"
                                                  : index === 1
                                                  ? result2Choice === "Perfect"
                                                  : index === 2
                                                  ? result3Choice === "Perfect"
                                                  : false
                                              }
                                              offText="Perfect"
                                              onChange={() => {
                                                if (index === 0) {
                                                  setResult1Choice("Perfect");
                                                } else if (index === 1) {
                                                  setResult2Choice("Perfect");
                                                } else if (index === 2) {
                                                  setResult3Choice("Perfect");
                                                }
                                              }}
                                              onText="Perfect"
                                            />
                                          </div>
                                          <div style={{ width: 150 }}>
                                            <Badge style={{ width: "100%" }}>
                                              <FormattedNumber
                                                decimalPlaces={0}
                                                value={result.confidence * 100}
                                              />
                                              % Confidence
                                            </Badge>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }) || <li>No Results</li>}
                            </ListGroup>
                            {terminatorResults && (
                              <>
                                <br />
                                <InputNumber
                                  label="Perfect Product Match ID"
                                  onChange={(_stringValue, numberValue) => {
                                    setPerfectProductMatchID(numberValue);
                                  }}
                                  value={perfectProductMatchID}
                                />
                                <br />
                                <Button
                                  block
                                  color="fancy"
                                  disabled={
                                    !result1Choice ||
                                    !result2Choice ||
                                    !result3Choice
                                  }
                                  icon={theme.icons.save}
                                  loading={loadingSave}
                                  onClick={handleRecord}
                                >
                                  Save Results
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                </Grid>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default App;
